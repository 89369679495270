import "./mainpage.css";

export default function Mainpage({ details }) {
  return (
    <div className="mainpage">
      <div className="mainContainer">
        {details.map((item, index) => {
          return (
            <div key={index} className="mainCard">
              <div className="leftItems">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <p className="title">{item.name}</p>
                </a>

                <p className="author">{item.author}</p>
                <p className="description">{item.description}</p>
                <p className="language">{item.language}</p>
              </div>
              <div className="rightItems">
                <p>
                  Most Active Contributor:
                  <span className="username">
                    {item.topContributorUsername}
                  </span>
                </p>
                <div className="contribution">
                  <p className="contributionSection">
                    <span className="contributionNumber">
                      {item.topContributorAdditions}
                    </span>
                    additions
                  </p>
                  <p className="contributionSection">
                    <span className="contributionNumber">
                      {item.topContributorDeletions}
                    </span>
                    deletions
                  </p>
                  <p className="contributionSection">
                    <span className="contributionNumber">
                      {item.topContributorCommits}
                    </span>
                    commits
                  </p>
                </div>
                <div className="updateDate">
                  <p>
                    Updated on <span>{item.updatedAt}</span>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
