import "./home.css";
import Mainpage from "../components/Mainpage";
import { useState } from "react";
import axios from "axios";

export default function Home() {
  const [searchString, setSearchString] = useState("");
  const [githubData, setGithubData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await axios.get(`${baseUrl}/${searchString}`);
    setGithubData(result.data.repositories);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <div className="home">
      <div className="inputSection">
        <form onSubmit={handleSubmit}>
          <input
            className="inputBox"
            type="text"
            placeholder="Search for a repository.."
            onInput={(e) => setSearchString(e.target.value)}
          />
          <button className="customButton" type="submit">
            &#x1F50D;
          </button>
        </form>
      </div>
      <Mainpage details={githubData} />
    </div>
  );
}
